import { useNavigate } from 'react-router-dom';

export function NavLogo() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/homepage');
  };

  return (
    <svg width="65" height="65" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick} className="cursor-pointer">
      <g clipPath="url(#clip0_699_15251)">
        <mask id="mask0_699_15251" style={{ maskType: 'luminance' } as React.CSSProperties} maskUnits="userSpaceOnUse" x="0" y="0" width="90" height="90">
          <path d="M90 0H0V90H90V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_699_15251)">
          <path d="M44.375 58.113H53.9019L64.8698 47.5256L75.5423 58.113H85.3653L64.8698 37.5742L44.375 58.113Z" fill="white" />
          <path d="M45.388 17.668L4.66406 58.1122L14.1904 58.11L45.3879 27.4117L55.9017 37.1561L60.3057 32.8067L45.388 17.668Z" fill="white" />
          <path d="M14.9062 69.2368C14.9062 67.4167 16.3103 66.125 18.2313 66.125C20.1523 66.125 21.5453 67.4167 21.5453 69.2368C21.5453 71.057 20.153 72.3487 18.2315 72.3487C16.31 72.3487 14.9065 71.0564 14.9065 69.2368M19.7706 69.2368C19.7706 68.1923 19.1079 67.5632 18.2318 67.5632C17.3557 67.5632 16.6813 68.1923 16.6813 69.2368C16.6813 70.2814 17.3552 70.9104 18.2318 70.9104C19.1085 70.9104 19.7706 70.2814 19.7706 69.2368Z" fill="white" />
          <path d="M28.8019 66.2148V72.2593H27.1394V71.5399C26.6794 72.0684 26.0054 72.3492 25.2636 72.3492C23.7469 72.3492 22.6797 71.495 22.6797 69.6304V66.2148H24.432V69.3722C24.432 70.3831 24.8813 70.8323 25.6564 70.8323C26.4647 70.8323 27.0497 70.3157 27.0497 69.2032V66.2148H28.8019Z" fill="white" />
          <path d="M36.609 68.7987V72.2587H34.8564V69.0681C34.8564 68.0909 34.4072 67.6416 33.6314 67.6416C32.789 67.6416 32.1825 68.1583 32.1825 69.2708V72.2591H30.4297V66.2142H32.1039V66.9225C32.5756 66.4058 33.2832 66.125 34.1039 66.125C35.5304 66.125 36.6092 66.9562 36.6092 68.7988" fill="white" />
          <path d="M42.0863 71.9636C41.7263 72.2225 41.1989 72.346 40.6598 72.346C39.2327 72.346 38.401 71.6153 38.401 70.1771V67.6942H37.4688V66.3464H38.401V64.875H40.1543V66.3464H41.6594V67.6942H40.1543V70.1547C40.1543 70.6719 40.4352 70.9527 40.9058 70.9527C41.1599 70.9558 41.4083 70.8769 41.6141 70.7277L42.0863 71.9636Z" fill="white" />
          <path d="M46.0625 68.3239C46.0625 66.0322 47.8042 64.3359 50.1408 64.3359C51.2528 64.3359 52.2525 64.6954 52.9376 65.426L52.5787 65.7961C51.9042 65.1334 51.0837 64.8639 50.1633 64.8639C48.1522 64.8639 46.6359 66.3465 46.6359 68.3239C46.6359 70.3014 48.1526 71.7845 50.1633 71.7845C51.0838 71.7845 51.9043 71.5037 52.5787 70.8404L52.9376 71.2116C52.2526 71.9412 51.2526 72.3124 50.1408 72.3124C47.804 72.3124 46.0625 70.6158 46.0625 68.3239Z" fill="white" />
          <path d="M59.9184 68.8309V72.2578H59.3568V68.8758C59.3568 67.5508 58.6491 66.8535 57.458 66.8535C56.0765 66.8535 55.2335 67.7414 55.2335 69.1341V72.2578H54.6719V63.9219H55.2337V67.6178C55.6386 66.8203 56.4474 66.348 57.5374 66.348C58.9639 66.348 59.9185 67.191 59.9185 68.8308" fill="white" />
          <path d="M67.3656 66.3945V72.2588H66.8265V71.0004C66.4441 71.8204 65.6466 72.3034 64.6132 72.3034C63.1188 72.3034 62.1641 71.4609 62.1641 69.8211V66.3945H62.7256V69.7759C62.7256 71.1019 63.4332 71.7983 64.6581 71.7983C65.9831 71.7983 66.8041 70.9109 66.8041 69.5182V66.3945H67.3656Z" fill="white" />
          <path d="M72.5242 66.3477V66.8977C72.4793 66.8977 72.4344 66.8864 72.3895 66.8864C71.086 66.8864 70.3116 67.7518 70.3116 69.2007V72.2564H69.75V66.3926H70.2892V67.673C70.648 66.8199 71.4236 66.348 72.5243 66.348" fill="white" />
          <path d="M73.375 69.3253C73.375 67.5726 74.6223 66.3477 76.3408 66.3477C77.2737 66.3477 78.0825 66.7077 78.5653 67.4265L78.1498 67.7297C77.7229 67.1344 77.0715 66.8537 76.3408 66.8537C74.9593 66.8537 73.9484 67.8421 73.9484 69.3253C73.9484 70.8086 74.9593 71.7963 76.3408 71.7963C77.0715 71.7963 77.7229 71.5155 78.1498 70.9202L78.5653 71.2235C78.082 71.9318 77.2737 72.3023 76.3408 72.3023C74.6223 72.3023 73.375 71.0669 73.375 69.3253Z" fill="white" />
          <path d="M85.3949 68.8309V72.2578H84.8333V68.8758C84.8333 67.5508 84.1249 66.8535 82.9345 66.8535C81.5524 66.8535 80.71 67.7414 80.71 69.1341V72.2578H80.1484V63.9219H80.71V67.6178C81.1144 66.8203 81.9231 66.348 83.0132 66.348C84.4403 66.348 85.3949 67.191 85.3949 68.8308" fill="white" />
          <path d="M12.0975 64.3945L9.19908 69.2816L6.25567 64.3945H4.75V72.2587H6.45783V67.642L8.76042 71.4279H9.58092L11.8951 67.5407L11.9063 72.2587H13.6142L13.5912 64.3945H12.0975Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_699_15251">
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}
