import { Nav } from 'components';

export function EventsPage() {
  return (
    <>
      <Nav />
      <div className="container">
        <h1>Eventos</h1>
      </div>
    </>
  );
}
